@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';









































































































































































@import 'src/css/mixins.scss';
.maintenance-costs {
  padding-top: 50px;
  padding-bottom: 60px;

  h1 {
    margin-bottom: 0px;
    margin-top: 0px;

    @media screen and (max-width: $breakpoint-sm) {
      padding: 0 10px;
      padding-bottom: 40px;
    }
  }

  .col-left {
    display: flex;
    align-items: flex-end;
    min-height: 240px;
    display: inline-grid;

    @media screen and (max-width: $breakpoint-sm) {
      padding: 0 10px;
    }

    .content-items {
      p {
        @include font-primary(16px, 'regular');
        color: #000;
        font-weight: 500;
        line-height: 34px;
        padding: 0 0 32px;
        letter-spacing: 0;
        font-family: SuzukiPRORegular;
      }

      span {
        @include font-primary(14px, 'semibold');
        line-height: 18px;
        color: #000;
        margin-top: 40px;
        padding-bottom: 60px;
        font-family: SuzukiPRORegular;
      }

      .cars-option {
        margin-top: 10px;
      }
    }
  }

  .col-right {
    padding: 0 10px;
    max-width: 500px;
    min-height: 240px;

    q-img {
      max-width: 500px;
    }
  }

  .price {
    margin-top: 30px;

    .row > div {
      height: 55px;
      display: flex;
      align-items: center;
    }

    .title-km,
    .title-months,
    .title-price {
      @include font-primary(18px, 'semibold');
      line-height: 28px;
      color: #000;
      font-family: SuzukiPRORegular;
    }

    .title-km {
      background-color: #efefef;
    }
    .title-months {
      background-color: #e0e0e0;
    }
    .title-price {
      background-color: #efefef;
    }

    .row-list {
      border-bottom: 1px solid #ddd;

      .text {
        @include font-primary(18px, 'regular');
        line-height: 28px;
        color: #4b5e65;
        font-family: SuzukiPRORegular;
      }
    }
  }
  .disclaimer {
    font-size: 10px;
    margin: 15px 0 0;
  }
}
