@mixin font-primary($size: 12px, $weight: 500, $style: normal) {
  font-size: $size;
  font-style: $style;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  @if ($weight == 'medium') {
    font-weight: 400;
  } @else if ($weight == 'regular') {
    font-weight: 500;
  } @else if ($weight == 'semibold') {
    font-weight: 600;
  } @else if ($weight == 'bolditalic') {
    font-weight: 800;
  } @else if ($weight == 'bold') {  
    font-weight: 800;
  } @else {
    font-weight: 400;
  }
}

@mixin font-secondary($size: 12px, $weight: 500, $style: normal) {
  font-family: 'Montserrat';
  font-size: $size;
  font-style: $style;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  @if ($weight == 'medium') {
    font-weight: 400;
  } @else if ($weight == 'regular') {
    font-weight: 500;
  } @else if ($weight == 'semibold') {
    font-weight: 600;
  } @else if ($weight == 'bolditalic') {
    font-weight: 800;
  } @else if ($weight == 'bold') {
    font-weight: 800;
  } @else {
    font-weight: 400;
  }
}
